<template>
  <div
    class="
      w-full
      h-[calc(100vh-82px)]
      fixed
      inset-0
      top-[82px]
      bg-cover
      menu
      backdrop-blur-lg
      z-10
      flex
    "
  >
    <div class="w-16 h-full flex flex-col justify-end pb-8">
      <img
        src="../../assets/images/cursor.svg"
        class="mx-auto animate-bounce"
        alt=""
      />
    </div>
    <div
      class="
        w-[330px]
        h-full
        overflow-auto
        flip
        overscroll-contain
        scrollable-content
      "
    >
      <div
        v-for="(product, i) in products"
        :key="i"
        class="p-5 flex content cursor-pointer"
        :class="{ 'bg-gray-300': activeIndex === i }"
        @click="selectProduct(i)"
      >
        <img
          :src="product.thumbnail"
          class="w-[150px] object-contain h-auto"
          alt=""
        />
        <div>
          <p class="font-bold mb-4">{{ product.name }}</p>
          <p>Mulai dari</p>
          <p class="font-bold">{{ product.price | formatRupiah }}</p>
        </div>
      </div>
    </div>
    <div class="flex-1 flex bg-slate-100">
      <div class="h-full px-12 py-7 bg-white">
        <p class="font-bold text-[26px]">{{ activeProduct.name }}</p>
        <p class="mb-8">{{ activeProduct.tagline }}</p>
        <p>Mulai dari</p>
        <p class="font-bold mb-8 text-2xl">
          {{ activeProduct.price | formatRupiah }}
        </p>
        <a
          href="https://daihatsu.co.id/request-quotes/"
          class="
            py-4
            text-center
            rounded-full
            border-2 border-azure
            font-bold
            text-azure
            block
            w-[200px]
            mb-4
          "
          @click="$emit('close')"
        >
          Get Request
        </a>
        <a
          href="https://daihatsu.co.id/simulasi-kredit/"
          class="
            py-4
            text-center
            rounded-full
            border-2 border-azure
            font-bold
            text-azure
            block
            w-[200px]
            mb-12
          "
          @click="$emit('close')"
        >
          Simulasi Kredit
        </a>
        <a
          :href="`https://daihatsu.co.id/product/${activeProduct.slug}`"
          class="
            py-4
            rounded-full
            bg-azure
            font-bold
            text-white
            inline-flex
            justify-center
            items-center
            w-[200px]
          "
          @click="$emit('close')"
        >
          Selengkapnya
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              /></svg
          ></span>
        </a>
      </div>
      <div class="flex-1 overflow-hidden">
        <transition
          enter-active-class="transition-all duration-500 ease-in"
          leave-active-class="transition-all duration-500 ease-in"
          enter-from-class="opacity-0 -translate-x-10"
          leave-to-class="opacity-0 translate-x-10"
          mode="out-in"
        >
          <img
            :key="activeProduct.id"
            class="w-full h-full object-contain object-left"
            :src="activeProduct.image"
            :alt="activeProduct.alt"
        /></transition>
      </div>
    </div>
  </div>
</template>

<script>
import { formatRupiah } from '../../utils.js'

export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  filters: {
    formatRupiah,
  },
  methods: {
    selectProduct(index) {
      this.activeIndex = index
    },
  },
  computed: {
    activeProduct() {
      return this.products[this.activeIndex]
    },
  },
}
</script>

<style scoped>
.menu {
  background-image: url('../../assets/images/bg/bg-menu.png');
}
.flip {
  direction: rtl;
}

.flip .content {
  direction: ltr;
}
/* Designing for scroll-bar */
::-webkit-scrollbar {
  @apply w-1.5;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-gray-300 rounded-md;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-500 rounded-md hover:bg-gray-700;
}
</style>
