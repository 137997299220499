// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/bg/bg-menu.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".menu[data-v-5d74f21a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.flip[data-v-5d74f21a]{direction:rtl}.flip .content[data-v-5d74f21a]{direction:ltr}[data-v-5d74f21a]::-webkit-scrollbar{width:.375rem}[data-v-5d74f21a]::-webkit-scrollbar-track{border-radius:.375rem;--tw-bg-opacity:1;background-color:rgba(209,213,219,var(--tw-bg-opacity))}[data-v-5d74f21a]::-webkit-scrollbar-thumb{border-radius:.375rem;--tw-bg-opacity:1;background-color:rgba(107,114,128,var(--tw-bg-opacity))}[data-v-5d74f21a]::-webkit-scrollbar-thumb:hover{--tw-bg-opacity:1;background-color:rgba(55,65,81,var(--tw-bg-opacity))}", ""]);
// Exports
module.exports = exports;
