import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=509cc022&scoped=true&shadow"
import script from "./Footer.vue?vue&type=script&lang=js&shadow"
export * from "./Footer.vue?vue&type=script&lang=js&shadow"
function injectStyles (context) {
  
  var style0 = require("./Footer.vue?vue&type=style&index=0&lang=css&shadow")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./Footer.vue?vue&type=style&index=1&id=509cc022&scoped=true&lang=css&shadow")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "509cc022",
  null
  ,true
)

export default component.exports