<template>
  <div class="bg-no-repeat bg-top bg-cover font-ubuntu footer">
    <slot></slot>
    <div class="md:px-20 pt-14">
      <div
        class="
          rounded-t-[54px]
          bg-white
          p-12
          pb-6
          flex flex-col
          md:flex-row md:flex-wrap
          lg:flex-nowrap
          md:justify-center
          lg:justify-between
          items-center
          md:items-stretch
          border-b-2
        "
      >
        <img
          height="84"
          width="55"
          src="../assets/images/daihatsu_cares.png"
          alt="Daihatsu Cares"
          class="md:hidden"
        />
        <p
          class="
            text-center
            lg:text-left
            md:max-w-[220px] md:mt-0
            tracking-tight
            mt-8
            mb-14
            lg:mb-0 lg:mr-10
            font-medium
          "
        >
          Daihatsu Access <br />
          1500898 <span class="md:hidden">| </span>
          <a href="mailto:hotline@daihatsu.astra.co.id"
            >hotline@daihatsu.astra.co.id</a
          >
        </p>
        <div
          class="
            w-full
            md:max-w-4xl md:flex md:flex-wrap md:justify-between md:space-x-4
            text-neutral-900
            mb-12
          "
        >
          <div v-for="(item, index) in footerMenus" :key="index" class="mb-5">
            <div
              class="
                md:mb-7
                font-medium
                flex
                justify-between
                cursor-pointer
                md:cursor-default
              "
              @click="selectMenu(index)"
            >
              <div>{{ item.title }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 md:hidden"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  :d="
                    activeIndex === index
                      ? 'M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
                      : 'M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                  "
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <a
              v-for="(x, i) in item.subMenus"
              :key="i"
              :href="x.link"
              class="text-neutral-600 leading-7 text-xs hidden md:block"
            >
              {{ x.title }}
            </a>
            <ExpandTransition>
              <div
                v-if="activeIndex === index"
                class="overflow-hidden md:hidden"
              >
                <a
                  v-for="(x, i) in item.subMenus"
                  :key="i"
                  :href="x.link"
                  class="text-neutral-600 leading-7 text-xs block"
                >
                  {{ x.title }}
                </a>
              </div>
            </ExpandTransition>
          </div>
        </div>
        <div class="lg:w-min lg:ml-10 lg:pr-10">
          <img
            height="84"
            width="55"
            src="../assets/images/daihatsu_cares.png"
            alt="Daihatsu Cares"
            class="hidden md:block md:mx-auto md:mb-4 lg:mr-auto lg:ml-0"
          />
          <p class="uppercase mb-4 lg:mt-4">Stay Connected With Us</p>
          <div class="flex space-x-1 justify-center lg:justify-start">
            <a href="https://www.facebook.com/daihatsuindonesia/">
              <img
                src="../assets/images/fb.svg"
                alt=""
                width="24"
                height="24"
                loading="lazy"
              />
            </a>
            <a href="https://www.instagram.com/daihatsuind">
              <img
                src="../assets/images/ig.svg"
                alt=""
                width="24"
                height="24"
                loading="lazy"
              />
            </a>
            <a href="https://twitter.com/daihatsuind">
              <img
                src="../assets/images/tw.svg"
                alt=""
                width="24"
                height="24"
                loading="lazy"
              />
            </a>
            <a href="https://www.tiktok.com/@daihatsuind">
              <img
                src="../assets/images/tt.svg"
                alt=""
                width="24"
                height="24"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="md:flex">
        <div
          class="
            leading-6
            text-xs
            py-2
            px-10
            bg-white
            md:bg-monza
            text-neutral-700
            md:text-white
          "
        >
          Privacy Policy |
          <a href="https://daihatsu.co.id/contact-us/">Contact Us</a>
        </div>
        <div
          class="
            bg-monza
            leading-6
            text-white text-xs
            py-2
            px-10
            md:text-right md:flex-1
          "
        >
          PT Astra Daihatsu Motor @2022 | Daihatsu Sahabatku
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExpandTransition from '../components/lib/ExpandTransition.vue'
import 'unfetch/polyfill'
import {
  vehicles,
  shoppingTools,
  afterSales,
  profile,
  ownership,
} from '../menu.js'

const footerMenus = [profile, vehicles, shoppingTools, ownership, afterSales]

export default {
  name: 'Footer',
  components: {
    ExpandTransition,
  },
  data() {
    return {
      footerMenus,
      activeIndex: null,
    }
  },
  created() {
    this.getProducts()
  },
  methods: {
    async getProducts() {
      try {
        const response = await fetch(
          'https://cms-revamp.daihatsu.co.id/api/list_product'
        )
        const {
          data: { product },
        } = await response.json()
        console.log(product)
        this.footerMenus[1].subMenus = product.map((x) => {
          return {
            title: x.name,
            link: `https://daihatsu.co.id/product/${x.slug}/`,
          }
        })
      } catch ({ response }) {
        console.log(response.data)
      }
    },
    selectMenu(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null
      } else {
        this.activeIndex = index
      }
    },
  },
}
</script>

<style>
@import url('../assets/css/main.css');
</style>

<style scoped>
.footer {
  background-image: url('../assets/images/footer/bg-footer.png');
}
</style>
